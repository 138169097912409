.count-box {
    flex: 1;
    display: flex;
    height: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all ease-out 300ms;
}

.count-box h1 {
    text-align: center;
    color: var(--orange);
}

.count-title {
    font-size: 23px;
    line-height: 28px;
    color: var(--orange);
    font-weight: bold;
}

.count-main {
    display: flex;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    color: var(--red-orange);
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: var(--digit-font);
}

.count-number {
    font-size: 88px;
    line-height: 88px;
}

.count-footer {
    font-size: 12px;
    line-height: 14px;
    color: var(--orange);
}