.share {
    opacity: 0;
    display: flex;
    gap: 10px;
    color: var(--orange);
    font-size: 14px;
    align-items: center;
    justify-content: center;
    transition: all ease-out 300ms;
    -webkit-transition: all ease-out 300ms;
    -moz-transition: all ease-out 300ms;
    -ms-transition: all ease-out 300ms;
    -o-transition: all ease-out 300ms;
}
.share.visible {
    opacity: 1;
}

a.ws, a.tg {
    background-size: contain;
    transition: all ease-out 300ms;
    -webkit-transition: all ease-out 300ms;
    -moz-transition: all ease-out 300ms;
    -ms-transition: all ease-out 300ms;
    -o-transition: all ease-out 300ms;
    -webkit-tap-highlight-color: transparent;
}


@media (hover:hover) {
    a.ws:hover, a.tg:hover {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
}
}

@media (hover:none) {
    a.ws:active, a.tg:active {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
}
}