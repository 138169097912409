.logo {
    font-size: 120px;
    line-height: 86px;
    color:var(--orange);
    font-weight: bold;
    text-align: center;
}

@media screen and (orientation:portrait) {
    .logo {
        font-size: 62px;
        line-height: 44px;
    }
}