.mbg {
    display: none;
}

@media screen and (orientation:portrait) {
    .mbg {
        display: block;
        flex: 1;
        align-self: stretch;
        margin-top: 20px;
        margin-bottom: 20px;
        background: url('../../img/mbg.svg') no-repeat center;
        background-size: contain;
        opacity: 0.9;
    }
}