a.roinext-logo {
    position: absolute;
    bottom: 20px;
    left: auto;
    right: auto;
    transform: translateY(0);
    transition: all ease-out 400ms;
    -webkit-transition: all ease-out 400ms;
    -moz-transition: all ease-out 400ms;
    -ms-transition: all ease-out 400ms;
    -o-transition: all ease-out 400ms;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

@media screen and (orientation:portrait) { 
    a.roinext-logo svg {
        height: 22px;
    }

    a.roinext-logo {
        bottom: 5px;
    }
}

@media (hover:hover) {
    a.roinext-logo:hover {
        transform: translateY(-10px) scale(1.1);
        -webkit-transform: translateY(-10px) scale(1.1);
        -moz-transform: translateY(-10px) scale(1.1);
        -ms-transform: translateY(-10px) scale(1.1);
        -o-transform: translateY(-10px) scale(1.1);
    }
}

@media (hover:none) {
    a.roinext-logo:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
}
}