.switch-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color:var(--light)
}

.switch-wrapper .icon svg {
    fill: var(--orange);
}

.switch-box {
    width: 58px;
    border: 1px solid var(--orange);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background: var(--switch-bg);
}
.switch-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    height: 23px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
}
.switch-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    user-select: none;
    background: var(--switch-counter-bg);
    font-size: 30px;
    font-weight: bold;
    color: var(--light-orange);
}
.switch-label {
    font-size: 14px;
}

@media (hover:hover) {
    .switch-btn:hover {
        background-color: var(--btn-hover);
    }
}

@media (hover:none) {
    .switch-btn:active {
        background-color: var(--btn-hover);
    }
}