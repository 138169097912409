@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Viga&display=swap');

:root {
    --base-gradient: radial-gradient(at top left, #263B43, #151D20);
    --main-font: "Inter", sans-serif;
    --digit-font: "Viga", sans-serif;
    --orange:#EF7C00;
    --red-orange:#FA5D10;
    --light-orange:rgb(255, 168, 0);
    --light:#D8D8D8;
    --dark:#192428;
    --bg-color:radial-gradient(at top left, #131E22, #0A0E10);
    --switch-bg: linear-gradient(90deg, #EF7C00 0%, #26373D 10%, #192428 90%, #EF7C00 100%);
    --switch-counter-bg: linear-gradient(0deg, #1E1E1E 0%, #192428 25%, #192428 75%, #000C10 100%);
    --btn-hover: rgba(255, 168, 0, 0.2);
}