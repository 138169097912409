@import url('./components/css/colors.css');

.wrapper {
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    background-size: cover;
    font-family: var(--main-font);
    user-select: none;
    position: relative;
    overflow: hidden;
}

.container {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.body {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap:20px;
    background: var(--base-gradient);
    height: 350px;
    padding: 20px;
    border: 2px solid var(--orange);
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0,0,0, 0.3), inset 0 0 20px rgba(0,0,0, 0.2);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-sizing: border-box;
}

.switches-wrapper {
    display: flex;
    flex-direction: column;
    gap:20px;
    flex: 1;
}

.switches-title {
    font-size: 20px;
    line-height: 24px;
    color: var(--light);
    text-align: center;
}

.switches {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.divider {
    border-left: 1px solid var(--orange);
    width: 0;
    align-self: stretch;
}

.bg-img-left {
    position: absolute;
    display: block;
    width: 256px;
    height: 378px;
    right: 50%;
    margin-right: 348px;
    background: url('./img/bg-left.png') no-repeat center right;
}
.bg-img-right {
    position: absolute;
    display: block;
    width: 270px;
    height: 378px;
    left: 50%;
    margin-left: 348px;
    background: url('./img/bg-right.png') no-repeat center left;
}

@media screen and (orientation:portrait) {
    .body {
        font-family: var(--main-font);
        flex-direction: column;
        align-items: stretch;
        justify-content: space-evenly;
        height: auto;
        padding: 30px 10px;
        margin-bottom: 40px;
    }
    
    .container {
        width: 90%;
        max-width: 420px;
    }

    .wrapper {
        justify-content: flex-end;
    }

    .switches-wrapper {
        gap:12px;
    }

    .divider {
        border-left: none;
        border-top: 1px solid var(--orange);
        width: auto;
        align-self: stretch;
    }

    .bg-img-left {
       display: none;
    }
    .bg-img-right {
        display: none;
    }
}